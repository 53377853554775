import React, { useEffect, useState } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import DropDown from "../../DropDown";
import IProduct from "../../../models/Products/IProduct";
import { Typography, Tooltip, IconButton } from "@cuda-networks/bds-core";
import { Delete } from "@cuda-networks/bds-core/dist/Icons/Core";

interface IFromSelectionItemProps {
  products: IProduct[];
  onSelectionChange: (product: IProduct, oldProduct?: IProduct) => void;
  onDelete(product: IProduct): void;
  selectedItem?: IProduct;
  showDeleteButton: boolean;
}

const FromSelectionItem: React.FC<IFromSelectionItemProps> = ({ products, onSelectionChange, onDelete, selectedItem, showDeleteButton }) => {
  const [selectedBundleId, setSelectedBundleId] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();

  useEffect(() => {
    if (selectedItem) {
      setSelectedProduct(selectedItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    const index = products.findIndex(product => product.id === selectedItem?.id);
    if (index > -1) {
      setSelectedBundleId(index + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const onBundleSelected = (value: number) => {
    setSelectedBundleId(value);
    const currentOption = products[value - 1];
    setSelectedProduct(currentOption);
    onSelectionChange(currentOption, selectedItem);
  };

  return (
    <Grid container item xs={12} style={{ backgroundColor: "#dfdfdf", borderRadius: "8px", padding: "5px", paddingLeft: "20px" }} className="ChangeFrom">
      <Grid item xs={12} style={{ flexBasis: "90%" }}>
        <Grid item xs={12}>
          <div data-testid="changeActiveServiceFromDropdownItem" className="changeActiveServiceFamily">
            <Typography
              data-testid="changeActiveServiceFromDropdownLabelItem"
              noWrap
              style={{
                fontSize: "12px",
                fontWeight: 700,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingTop: "10px",
              }}
            >
              Active Service
            </Typography>

            <DropDown label={""} options={products.map(x => x.name)} selectedOptionId={selectedBundleId} handleChange={onBundleSelected} required={false} error={""} disabled={false} />
          </div>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: 10 }}>
          <div data-testid="changeActiveServiceFromDescriptionItem" className="ChangeActiveService">
            <Typography
              data-testid="changeActiveServiceFromDescriptionLabelItem"
              noWrap
              style={{
                fontSize: "12px",
                fontWeight: 700,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Unique ID
            </Typography>

            {selectedProduct ? (
              <Grid container item xs={12}>
                <Grid item xs={8} style={{ textAlign: "left", paddingBottom: "10px" }}>
                  <Typography variant="subtitle1" style={{ fontSize: "14px" }} data-testid="changeActiveServiceFromOrderlineLabelItem">
                    {selectedProduct?.subname}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right", paddingBottom: "10px" }}>
                  <Typography variant="subtitle1" style={{ fontSize: "14px" }} data-testid="changeActiveServiceFromSerialLabelItem">
                    Serial#: {selectedProduct?.serials[0].serial}{" "}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container item xs={12}>
                <Grid item xs={12} style={{ textAlign: "left", paddingBottom: "10px" }}>
                  <Typography disabled variant="subtitle1" data-testid="changeActiveServiceFromEmptyLabelItem" style={{ color: "gray", fontSize: "14px" }}>
                    Select a Service...
                  </Typography>
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ flexBasis: "10%" }}>
        <Grid container item style={{ width: "2", justifyContent: "flex-end", alignItems: "flex-start" }}>
          <Tooltip title="Delete">
            <IconButton disabled={!showDeleteButton} onClick={() => onDelete(selectedItem!)} style={{ padding: "2px", color: showDeleteButton ? "#6c6c6c" : "#aaaaaa" }} data-testid="changeActiveServiceFromDeleteButton">
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FromSelectionItem;
