import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import ToSelectionItem from "../ToSelectionItem";
import IProduct from "../../../../models/Products/IProduct";
import { Typography } from "@cuda-networks/bds-core";
import IChangeServiceSelection from "../../../../models/Products/IChangeServiceSelection";

interface IToStepProps {
  availableOptions: IProduct[];
  selection: IChangeServiceSelection;
  onToSelectionChanged: (product: IProduct) => void;
}

const ToStep: React.FC<IToStepProps> = ({ availableOptions, selection, onToSelectionChanged }) => {
  return (
    <Grid container style={{ width: "600px" }}>
      <Typography data-testid="changeServicesDialogSubtitle" variant="subtitle2" style={{ fontWeight: "600", paddingLeft: "16px", paddingRight: "16px" }}>
        TO
      </Typography>
      <Typography data-testid="changeServicesDialogLabel1" variant="subtitle2" style={{ paddingLeft: "16px", paddingRight: "16px" }}>
        In the fields below, select the single Service to which you want to change your previously selected services. When complete, click the <b>Next</b> button.
      </Typography>
      <div style={{ height: "16px", width: "1px" }} />
      <Grid item xs={12}>
        <div style={{ marginBottom: "10px", paddingLeft: "16px", paddingRight: "16px" }} data-testid="changeServicesTo">
          <ToSelectionItem products={availableOptions} onToSelectionChanged={onToSelectionChanged} selectedItem={selection.to} />
        </div>
      </Grid>
    </Grid>
  );
};

export default ToStep;
