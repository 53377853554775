import { Typography } from "@cuda-networks/bds-core";
import React from "react";

const ConfirmationMessage = () => {
  return (
    <div className="AccountIcons" data-testid="confirmChangeServices">
      <Typography variant="subtitle2" gutterBottom>
        I am aware that this change is permanent and cannot be undone.
      </Typography>
    </div>
  );
};

export default ConfirmationMessage;
