import React, { useEffect, useState } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@cuda-networks/bds-core";
import { handleBackdropClick } from "../../../utility";
import ConfirmationCheckbox from "../../ConfirmationCheckbox";
import FromStep from "./DialogSteps/FromStep";
import ToStep from "./DialogSteps/ToStep";
import ConfirmationMessage from "./ConfirmationMessage";
import SummaryStep from "./DialogSteps/SummaryStep";
import IProduct from "../../../models/Products/IProduct";
import IChangeServiceSelection from "../../../models/Products/IChangeServiceSelection";
import { getChangeServicesDestinationOptionsAction } from "../../../actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@cuda-networks/bds-core";
import { IAppState } from "../../../store/store";

interface IChangeServicesDialogProps {
  accountProducts: IProduct[];
  partnerProducts: IProduct[];
  showDialog: boolean;
  onSubmit: (selection: IChangeServiceSelection) => void;
  onCancel: () => void;
  actionInProgress: boolean;
}

const DialogStepsTitle = "Change Services";
const ConfirmationStepTitle = "Confirm Change Services";
const NoServicesSelected = "Select services to change";
const NoServicesSelectedToChangeTo = "Select a service to change to";
const NoValidOptionAvailableToChangeTo = "No valid options available for the selected services";

const ChangeServicesDialog: React.FC<IChangeServicesDialogProps> = ({ accountProducts, partnerProducts, showDialog, onSubmit, onCancel, actionInProgress }) => {
  const dispatch = useDispatch();
  const loadingOptionsToChangeServicesTo = useSelector((state: IAppState) => state.productState.loadingOptionsToChangeServicesTo);
  const [activeStep, setActiveStep] = useState(0);
  const [confirmed, setConfirmed] = useState(false);
  const [nextStepButtonLabel, setNextStepButtonLabel] = useState("NEXT");
  const [title, setTitle] = useState(DialogStepsTitle);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const [nextButtonTooltip, setNextButtonTooltip] = useState("");
  const [availableChangeToOptions, setAvailableChangeToOptions] = useState<any[]>([]);
  const emptyProd = { id: "" } as IProduct;
  const [selection, setSelection] = useState<IChangeServiceSelection>({ from: [emptyProd] });

  useEffect(() => {
    switch (activeStep) {
      case 0:
        let enabled = true;
        let tooltip = "";
        if (selection.from.length > 0) {
          for (let i = 0; i < selection.from.length; i++) {
            if (selection.from[i].id === "") {
              enabled = false;
              tooltip = NoServicesSelected;
              break;
            }
          }
        } else {
          enabled = false;
          tooltip = NoServicesSelected;
        }
        setNextButtonEnabled(enabled);
        setNextButtonTooltip(tooltip);
        setNextStepButtonLabel("NEXT");
        setTitle(DialogStepsTitle);
        break;
      case 1:
        let selectionMade = selection.to !== undefined;
        setNextButtonEnabled(selectionMade);
        if (!selectionMade) {
          setNextButtonTooltip(NoServicesSelectedToChangeTo);
        }
        setNextStepButtonLabel("NEXT");
        setTitle(DialogStepsTitle);
        break;
      case 2:
        setNextButtonEnabled(true);
        setNextStepButtonLabel("CHANGE");
        setTitle(DialogStepsTitle);
        break;
      case 3:
        setNextButtonTooltip("");
        setNextButtonEnabled(confirmed);
        setNextStepButtonLabel("CONFIRM");
        setTitle(ConfirmationStepTitle);
    }
  }, [selection, activeStep, confirmed]);

  useEffect(() => {
    setConfirmed(false);
  }, [selection]);

  const onConfirmChanged = (value: boolean) => {
    setConfirmed(value);
    setNextButtonEnabled(value);
  };

  function computeAvailableToChangeOptions(response: any[], products: IProduct[]): IProduct[] {
    let changeOptions: IProduct[] = [];
    response.forEach(element => {
      let product = products.find(x => x.id === element.orderLineItemId);
      if (product !== undefined) {
        changeOptions.push(product);
      }
    });
    return changeOptions;
  }

  const onFromSelectionChanged = (product: IProduct, oldProduct?: IProduct) => {
    let newSelection = { ...selection };
    if (oldProduct === undefined) {
      if (product.id === "") {
        newSelection = { from: [...selection.from, product] };
      } else {
        const fromSelection = selection.from.filter(product => product.id !== "");
        const newFrom = [...fromSelection, product];
        newSelection = { from: newFrom };
      }
    } else {
      const newFrom = selection.from.map(x => (x.id === oldProduct.id ? product : x));
      newSelection = { from: newFrom };
    }

    setSelection(newSelection);
    if (newSelection.from.some(x => x.id === "")) {
      return;
    }
    setNextButtonEnabled(false);
    getChangeServicesOptions({ ...newSelection, to: partnerProducts[0] }).then(response => {
      if (response) {
        if (response.availableOptions.length > 0) {
          setAvailableChangeToOptions(computeAvailableToChangeOptions(response.availableOptions, partnerProducts));
          if (!selection.from.some(x => x.id === "")) {
            setNextButtonEnabled(true);
          }
        } else {
          setNextButtonTooltip(NoValidOptionAvailableToChangeTo);
          setNextButtonEnabled(false);
        }
      }
    });
  };

  const onDelete = (product?: IProduct) => {
    let newSelection = { ...selection };
    if (product !== undefined) {
      const newFrom = selection.from.filter(x => x.id !== product.id);
      newSelection = { from: newFrom };
    } else {
      const newFrom = selection.from.filter(product => product.id !== "");
      newSelection = { from: newFrom };
    }

    setSelection(newSelection);
    if (product === undefined) {
      return;
    }

    setNextButtonEnabled(false);
    getChangeServicesOptions({ ...newSelection, to: partnerProducts[0] }).then(response => {
      if (response) {
        if (response.availableOptions.length > 0) {
          setAvailableChangeToOptions(computeAvailableToChangeOptions(response.availableOptions, partnerProducts));
          setNextButtonEnabled(true);
        } else {
          setNextButtonTooltip(NoValidOptionAvailableToChangeTo);
          setNextButtonEnabled(false);
        }
      }
    });
  };

  const onToSelectionChanged = (product: IProduct) => {
    if (product.id === "") {
      setSelection({ ...selection, to: undefined });
      setNextButtonEnabled(false);
      return;
    }
    setSelection({ ...selection, to: product });
    setNextButtonEnabled(true);
  };

  const nonSelectedProduct = accountProducts.filter(product => !selection?.from?.some(selected => selected.id === product.id));
  nonSelectedProduct.sort((a, b) => a.name.localeCompare(b.name));

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <FromStep activatedProducts={nonSelectedProduct} selection={selection} onFromSelectionChanged={onFromSelectionChanged} onDelete={onDelete} />;
      case 1:
        return <ToStep availableOptions={availableChangeToOptions} selection={selection} onToSelectionChanged={onToSelectionChanged} />;
      case 2:
        return <SummaryStep selection={selection} />;
      case 3:
        return <ConfirmationCheckbox message={<ConfirmationMessage />} onConfirmChanged={value => onConfirmChanged(value)} confirmed={confirmed} />;
    }
  }

  const getChangeServicesOptions = (selection: IChangeServiceSelection) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getChangeServicesDestinationOptionsAction(selection));
      resolve(result);
    });

  const handleNext = () => {
    switch (activeStep) {
      case 0:
      case 1:
      case 2:
        setActiveStep(activeStep + 1);
        break;
      case 3:
        onSubmit(selection);
        break;
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      onCancel();
    }
  };

  return (
    <Dialog open={showDialog} data-testid="changeServicesDialog" onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)}>
      <DialogTitle data-testid="changeServicesDialogTitle" id="alert-dialog-title">
        <Typography
          variant="h5"
          style={{
            fontWeight: 500,
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
        >
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <div className="DialogContentDiv" style={{ padding: 8 }}>
          <Grid item xs={12}>
            {getStepContent(activeStep)}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: 15 }}>
        <div style={{ display: "flex", gap: "8px" }}>
          <Button data-testid="cancelChangeServicesButton" variant="text" size="large" onClick={onCancel} disabled={actionInProgress}>
            CANCEL
          </Button>
          {activeStep !== 0 && (
            <Button data-testid="backChangeServicesButton" variant="text" size="large" onClick={handleBack} disabled={actionInProgress}>
              BACK
            </Button>
          )}
          <Tooltip title={!nextButtonEnabled || actionInProgress || loadingOptionsToChangeServicesTo ? nextButtonTooltip : ""}>
            <Button data-testid="nextChangeServicesButton" type={"submit"} size="large" disabled={!nextButtonEnabled || actionInProgress || loadingOptionsToChangeServicesTo} isLoading={actionInProgress || loadingOptionsToChangeServicesTo} onClick={handleNext}>
              {nextStepButtonLabel}
            </Button>
          </Tooltip>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeServicesDialog;
