import React, { useEffect, useState } from "react";
import { Typography, LinearProgress, Button, Grid } from "@cuda-networks/bds-core";
import ProductAccordion from "./ProductAccordion";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { changeSerialAction, deactivateProductsForAccountAction, getParentMspProductsAction, getProductSerialsToDisplayAction, getSerialsAction, setProductExpandedStatusAction, setSelectedProductAction, retryActivationProductAction, getProductsAction, getProductSerialForUser, unassignProductsForAccountAction, exportCsvReportAction, cancelSerialAction, setTablePropsForSerial, getAccountHasAnyChangeServicesOptionsAction } from "../../actions/productActions";
import ProductsButtonActions from "./ProductsButtonActions";
import MspType from "../../models/MspType";
import IProductFamily from "../../models/Products/IProductFamily";
import IAccount from "../../models/IAccount";
import ProductFamily from "../../models/Products/ProductFamily";
import ShowSerialsDialog from "./Serials/ShowSerialsDialog";
import MigrateDialog from "./Serials/MigrateDialog";
import IProduct from "../../models/Products/IProduct";
import { goToAccountAction, setPrevSelectedAccountAction, getLastUsageSyncTimeAction, loadParentAccountWithChildrenAction, cancelLoadCustomerParentAndItsChildrenAction, cancelLoadSubpartnerCustomersAction, getCustomersAction, filterAccountsAction } from "../../actions/accountActions";
import ShowEditSerialsDialog from "./Serials/ShowEditSerialsDialog";
import { findAccountById, hasProductFilters, searchByAccountId, showUnassignButton } from "../../Utilities/accountsHelper";
import ShowUnassignedDialog from "./Serials/ShowUnassignedDialog";
import { isStringNullOrEmpty, getDateToDisplay, getCanManageIntegrations, getCanExportDataUsage, getCanExportUserBillingList } from "../../utility";
import { getNoProductsMessage, productIsBBS, isSecureEdgeServiceSku, isSecureEdgeProduct, setGenerateCsvReportButtonColor, nameIsESS } from "../../Utilities/productsHelper";
import { Dispatch } from "redux";
import ISerial from "../../models/Products/ISerial";
import ShowMigrateConfirmationDialog from "./Serials/ShowMigrateConfirmationDialog";
import { cancelCurrentAction, setSnackBarMessage } from "../../actions/generalActions";
import ProductFilters from "./ProductFilters";
import ExportUsageDialog from "./ExportUsageDialog";
import ActionMessageType from "../../models/ActionMessageType";
import { NoMspTypeError } from "../../models/constants";
import DetailsTabs from "../../models/DetailsTabs";
import TabTitle from "../TabTitle";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";
import ShowDeleteSerialDialog from "./Serials/ShowDeleteSerialDialog";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import useDocumentTitle, { PageTitles } from "../../Utilities/useDocumentTitle";
import { UrlFilterExtractor } from "../Accounts/FilterAccounts/UrlFilterExtractor";
import ExportDataSplitButton from "./ExportDataSplitButton";
import ExportUserBillingListDialog from "./ExportUserBillingListDialog";

export const getProductTabContent = (productsToDisplay: IProductFamily[], expandedStatus: Record<ProductFamily, boolean>, selectedAccount: IAccount | undefined, expandProductHandler: (productFamily: string, isExpanded: boolean) => void, handleOpenEditDialogHandler: (product: IProduct, selectedAccountType: string) => void, hasIBU: boolean, echoUrl?: string): React.JSX.Element | React.JSX.Element[] => {
  if (productsToDisplay && productsToDisplay.length > 0) {
    const acc = productsToDisplay?.map((item, idx) => {
      return (
        <div key={"paccordionDiv" + idx} data-testid={"productFamily" + idx}>
          <ProductAccordion key={"paccordion" + idx} tableKey={item.productType + idx} productFamily={item} expanded={expandedStatus[item.productType]} handleExpandProduct={expandProductHandler} handleOpenEditDialog={handleOpenEditDialogHandler} />
        </div>
      );
    });
    if (hasIBU) {
      acc.push(
        <div key={"paccordionDiv"} data-testid={"hasIntronisBackup"}>
          <ProductAccordion key={"paccordion"} tableKey={"x"} productFamily={undefined} expanded={expandedStatus[ProductFamily.INTRONIS_BACKUP]} handleExpandProduct={expandProductHandler} handleOpenEditDialog={handleOpenEditDialogHandler} hasIBU={hasIBU} />
        </div>,
      );
    }
    return acc;
  } else {
    if (hasIBU) {
      return (
        <div key={"paccordionDiv"} data-testid={"hasIntronisBackup"}>
          <ProductAccordion key={"paccordion"} tableKey={"x"} productFamily={undefined} expanded={expandedStatus[ProductFamily.INTRONIS_BACKUP]} handleExpandProduct={expandProductHandler} handleOpenEditDialog={handleOpenEditDialogHandler} hasIBU={hasIBU} />
        </div>
      );
    } else {
      return <Typography data-testid={"noProductsMessage"}>{getNoProductsMessage(selectedAccount)}</Typography>;
    }
  }
};

export interface IMigrationObject {
  orderLineItem: IProduct;
  serial: ISerial;
  lineItem: IProduct;
}

const ProductsTab: React.FC = () => {
  const dispatch = useDispatch();
  const loadingProducts = useSelector((state: IAppState) => state.productState.loadingProducts);
  const productsToDisplay = useSelector((state: IAppState) => state.productState.productsToDisplay);
  const accountsProducts = useSelector((state: IAppState) => state.productState.accountsProducts);
  const expandedStatus = useSelector((state: IAppState) => state.productState.expandedStatus);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loadingHasSubpartnersWithProducts = useSelector((state: IAppState) => state.productState.loadingHasSubpartnersWithProducts);
  const hasSubpartnersWithProducts = useSelector((state: IAppState) => state.productState.hasSubpartnersWithProducts);
  const selectedAccountToFilterProductsForBA = useSelector((state: IAppState) => state.accountState.selectedAccountToFilterProductsForBA);
  const [accordions, setAccodions] = useState<React.JSX.Element | React.JSX.Element[]>();
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const lastUsageSyncTimeFromAccountState = useSelector((state: IAppState) => state.accountState.lastUsageSyncTime);
  const [lastUsageSyncTimeToDisplay, setLastUsageSyncTimeToDisplay] = useState(lastUsageSyncTimeFromAccountState);
  const loadingAllChildrenAccountNames = useSelector((state: IAppState) => state.accountState.loadingAllChildrenAccountNames);
  const loadingCurrency = useSelector((state: IAppState) => state.productState.loadingCurrency);
  const serialsToDisplay = useSelector((state: IAppState) => state.productState.serialsToDisplay);
  const [showSerials, setShowSerials] = useState(false);
  const [showEditSerials, setShowEditSerials] = useState(false);
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const accountsNames = useSelector((state: IAppState) => state.accountState.accountsNames);
  const selectedProduct = useSelector((state: IAppState) => state.productState.selectedProduct);
  const echoUrl = useSelector((state: IAppState) => state.generalState.echoUrl);
  const [showLink, setShowLink] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [loadingAccountToGoTo, setLoadingAccountToGoTo] = useState(false);
  const [parentIsNotMsp, setParentIsNotMsp] = useState(false);
  const [showUnassignedDialog, setShowUnassignedDialog] = useState(false);
  const [showDeleteSerialDialog, setShowDeleteSerialDialog] = useState(false);
  const [showMigrateDialog, setShowMigrateDialog] = useState(false);
  const [showUnassignedEditSerial, setShowUnassignedEditSerial] = useState(false);
  const prevSelectedAccount = useSelector((state: IAppState) => state.accountState.prevSelectedAccount);
  const [migrationOptions, setMigrationOptions] = useState<IProduct[]>([]);
  const [showConfirmMigrate, setShowConfirmMigrate] = useState(false);
  const [migrationObject, setMigrationObject] = useState<IMigrationObject | undefined>();
  const [canManageIntegration, setCanManageIntegration] = useState(false);
  const [manageIntegrationsButtonColor, setManageIntegrationsButtonColor] = useState(hasSubpartnersWithProducts ? "secondary" : "primary");
  const [showGenereateCsvReport, setShowGenereateCsvReport] = useState(false);
  const [showExportUserBillingList, setShowExportUserBillingList] = useState(false);
  const isSelectedAccountBa = selectedAccount?.type === MspType.BillingAggregator;
  const parentProducts = useSelector((state: IAppState) => state.productState.parentProductsFamilies);
  const generateCsvReportButtonColor = setGenerateCsvReportButtonColor(mspAccounts, selectedAccount, mspAccountLoggedIn, parentProducts, productsToDisplay, loadingProducts, canManageIntegration, accountsProducts);
  const loggedUser = useSelector((state: IAppState) => state.generalState.loggedUser);
  const [showHideLinearProgress, setshowHideLinearProgress] = useState(false);
  const [canExportDataUsage, setCanExportDataUsage] = useState(false);
  const [canExportDataUsageAndBillingList, setCanExportDataUsageAndBillingList] = useState(false);
  const hasIBU = useSelector((state: IAppState) => state.accountState.hasIBU);
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);
  const filters = new UrlFilterExtractor(window.location.search, accountsOrders).extractFilters();
  const loadingAccountInfo = useSelector((state: IAppState) => state.accountState.loadingAccountInfo);
  const serialTableState = useSelector((state: IAppState) => state.productState.serialTableState);
  const [serialForUser, setSerialForUser] = useState(serialsToDisplay[0]);
  const selectedSku = selectedProduct?.sku;
  const isSecureEdgeEdgeService = isSecureEdgeServiceSku(selectedSku);
  const isSecureEdgeProducts = isSecureEdgeProduct(selectedSku);

  const navigate = useNavigate();
  const location = useLocation();

  useDocumentTitle(selectedAccount, PageTitles.ProductsAndServices);

  const handleExpandProduct = (productFamily: string, isExpanded: boolean) => {
    dispatch(setProductExpandedStatusAction(productFamily, isExpanded));
  };

  useEffect(() => {
    const handlePopState = (event: any) => {
      setShowEditSerials(false);
      setShowSerials(false);
      setShowUnassignedDialog(false);
      setShowDeleteSerialDialog(false);
      setShowConfirmMigrate(false);
      setShowMigrateDialog(false);
      setShowGenereateCsvReport(false);
    };
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  useEffect(() => {
    let isMounted = true;
    const loadLastUsageSyncTime = () =>
      new Promise<any>((resolve, reject) => {
        const result = dispatch(getLastUsageSyncTimeAction());
        resolve(result);
      });
    loadLastUsageSyncTime().then(result => {
      if (isMounted) {
        setLastUsageSyncTimeToDisplay(result);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    if (hasSubpartnersWithProducts) {
      setManageIntegrationsButtonColor("secondary");
      if (loadingProducts || loadingHasSubpartnersWithProducts || selectedAccount?.id !== mspAccountLoggedIn.id) {
        setManageIntegrationsButtonColor("primary");
      }
    }

    const targetPath = `/accounts/${selectedAccount?.id}/products-services`;

    if (mspAccountLoggedIn.type === MspType.Customer) {
      if (location.pathname !== targetPath) {
        console.debug(`navigate to targetPath:  ${targetPath} != pathname:${location.pathname}`);
        navigate(targetPath);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubpartnersWithProducts, selectedAccount, loadingProducts, loadingHasSubpartnersWithProducts]);

  useEffect(() => {
    if ((loadingProducts || loadingAllChildrenAccountNames || loadingCurrency || loadingAccountInfo) && !isSelectedAccountBa) {
      setshowHideLinearProgress(true);
    } else {
      setshowHideLinearProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProducts, loadingAllChildrenAccountNames, loadingCurrency, isSelectedAccountBa, loadingAccountInfo]);

  useEffect(() => {
    const tabContent = getProductTabContent(productsToDisplay, expandedStatus, selectedAccount, handleExpandProduct, handleOpenEditDialog, hasIBU, echoUrl);
    setAccodions(tabContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsToDisplay, expandedStatus, selectedAccount, hasIBU]);

  useEffect(() => {
    if (serialsToDisplay?.length > 0) {
      setSerialForUser(serialsToDisplay[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, serialsToDisplay]);

  const handleOpenEditDialog = (e: IProduct, selectedAccountType: string) => {
    dispatch(setSelectedProductAction(e));
    dispatch(getProductSerialsToDisplayAction(e));
    setShowLink(false);
    if (selectedAccountType === MspType.Customer) {
      setShowEditSerials(true);
      setShowSerials(false);
      dispatch(getProductSerialForUser(selectedAccount));
    } else {
      if (productIsBBS(e)) {
        setShowEditSerials(true);
        if (!isStringNullOrEmpty(e.account) && e.serials && e.serials.length > 0) {
          const goToAcccount = findAccountById(mspAccounts, accountsNames, e.serials[0].accountId);
          if (goToAcccount) {
            handleGoToAccount(goToAcccount.id);
          }
        } else if (e.subPartnerId !== null && e.subPartnerId !== undefined && isStringNullOrEmpty(e.account)) {
          if (selectedAccountType === MspType.Partner) {
            handleGoToAccount(e.subPartnerId, e);
          }
        }
      } else {
        setShowEditSerials(false);
        setShowSerials(true);
      }
    }
  };

  const handleOnCancel = () => {
    setShowEditSerials(false);
    setShowSerials(false);
    if (selectedAccount?.type !== MspType.Customer) {
      dispatch(setTablePropsForSerial({ ...serialTableState, skip: 0 }));
    }
    dispatch(setSelectedProductAction(null));
    navigateToSelectedAccount();
  };

  const unassignProduct = (product: IProduct) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(unassignProductsForAccountAction(selectedAccount, product));
      resolve(result);
    });

  const handleOnUnassign = () => {
    if (selectedProduct !== undefined) {
      setActionInProgress(true);
      unassignProduct(selectedProduct).then(result => {
        setActionInProgress(false);
        if (result) {
          if (selectedAccount) {
            if (productIsBBS(selectedProduct)) {
              dispatch(getParentMspProductsAction(selectedAccount));
            }
            dispatch(getProductsAction(selectedAccount));
            setShowUnassignedDialog(false);
            dispatch(
              setSnackBarMessage({
                message: ActionMessages[ActionTypes.UnassignProduct].successMessage,
                type: ActionMessageType.Success,
              }),
            );
            setShowUnassignedEditSerial(false);
            if (showLink) {
              onHandleBackToMSPAccounts(true);
            }
          }
        }
      });
    }
  };

  const deactivateProduct = (product: IProduct) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(deactivateProductsForAccountAction(mspAccountLoggedIn, product, selectedAccount));
      resolve(result);
    });

  const cancelSerial = (product: IProduct, serialForUser: ISerial) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(cancelSerialAction(mspAccountLoggedIn, product, selectedAccount, serialForUser.bccProductId));
      resolve(result);
    });

  const handleOnDeactivate = () => {
    if (selectedProduct !== undefined) {
      setActionInProgress(true);

      const deactivatePromise = productIsBBS(selectedProduct) ? deactivateProduct(selectedProduct) : cancelSerial(selectedProduct, serialForUser);

      deactivatePromise.then(result => {
        setActionInProgress(false);
        if (result) {
          if (selectedAccount) {
            dispatch(getParentMspProductsAction(selectedAccount));
            if (isStringNullOrEmpty(serialForUser.serial)) {
              setShowEditSerials(false);
            } else {
              setShowDeleteSerialDialog(false);
            }
            dispatch(getProductsAction(selectedAccount));
            if (nameIsESS(selectedProduct.type)) {
              dispatch(getAccountHasAnyChangeServicesOptionsAction(selectedAccount));
            }
            if (productIsBBS(selectedProduct)) {
              dispatch(
                setSnackBarMessage({
                  message: ActionMessages[ActionTypes.DeactivateSerial].successMessage,
                  type: ActionMessageType.Success,
                }),
              );
            } else {
              dispatch(
                setSnackBarMessage({
                  message: ActionMessages[ActionTypes.RemoveSerial].successMessage,
                  type: ActionMessageType.Success,
                }),
              );
            }
            if (showLink) {
              onHandleBackToMSPAccounts(true);
            }
          }
          if (hasProductFilters(filters)) {
            dispatch(filterAccountsAction(filters));
            navigate("/filters" + window.location.search);
          }
        }
      });
    }
  };

  const handleOnCancelEditSerial = () => {
    handleOnCancel();
  };

  const loadParentAccountWithChildren = (accountId: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(loadParentAccountWithChildrenAction(accountId, true));
      resolve(result);
    });

  const loadChildren = (accountId: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getCustomersAction(accountId, true));
      resolve(result);
    });

  const handleGoToAccount = (accountId: number, product?: IProduct): void => {
    const isSubpartner: boolean = product !== undefined;
    setShowLink(true);
    setShowSerials(false);
    setSerialForUser(serialsToDisplay.filter(e => e.accountId === accountId)[0]);

    function handleGoToAcc(acc: IAccount) {
      dispatch(getProductSerialForUser(acc, product));
      dispatch(goToAccountAction(acc));
    }

    function loadParentAndGoToAccount(accountId: number) {
      setLoadingAccountToGoTo(true);
      loadParentAccountWithChildren(accountId).then(result => {
        if (result) {
          const partnerId = result.account?.closestParentId;
          if (partnerId) {
            setParentIsNotMsp(false);
            handleGoToAcc({ ...result.account, partnerId: partnerId });
          } else {
            if (result.reason === NoMspTypeError) {
              setParentIsNotMsp(true);
            }
          }
        }
        setLoadingAccountToGoTo(false);
      });
    }

    function loadChildrenAndGoToAccount(accountId: number) {
      setLoadingAccountToGoTo(true);
      loadChildren(accountId).then(result => {
        if (result) {
          handleGoToAcc(result);
        }
        setLoadingAccountToGoTo(false);
      });
    }

    dispatch(setPrevSelectedAccountAction(selectedAccount));
    try {
      if (isSubpartner) {
        loadChildrenAndGoToAccount(accountId);
      } else {
        const goToAcc = searchByAccountId(mspAccounts, accountId);
        if (goToAcc) {
          setParentIsNotMsp(false);
          handleGoToAcc(goToAcc);
        } else {
          loadParentAndGoToAccount(accountId);
        }
      }
    } catch {
      loadParentAndGoToAccount(accountId);
    }
    setShowEditSerials(true);
  };

  const handleOpenUnassignedDialog = () => {
    setShowUnassignedDialog(true);
    if (showUnassignedEditSerial) {
      setShowEditSerials(false);
    } else {
      setShowSerials(false);
    }
  };
  const handleOnCancelUnassigned = () => {
    setShowUnassignedDialog(false);
    if (showUnassignedEditSerial) {
      setShowEditSerials(true);
      setShowUnassignedEditSerial(false);
    } else {
      setShowSerials(true);
    }
  };
  const handleOnDeactivateSerial = () => {
    if (!isStringNullOrEmpty(serialForUser.serial)) {
      setShowEditSerials(false);
      setShowDeleteSerialDialog(true);
    } else {
      handleOnDeactivate();
    }
  };

  const handleOnBackDeactivate = () => {
    setShowEditSerials(true);
    setShowDeleteSerialDialog(false);
  };

  const handleOnCloseDeactivate = () => {
    setShowDeleteSerialDialog(false);
    navigateToSelectedAccount();
  };

  const getRefreshedSerialsToDisplay = (selectedProduct: IProduct | undefined, refreshSerials: boolean) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getProductSerialsToDisplayAction(selectedProduct, refreshSerials));
      resolve(result);
    });

  const handleSerialsVisibility = (prevSelectedAccount: IAccount | undefined, selectedProduct: IProduct | undefined, serialsToDisplay: ISerial[]) => {
    dispatch(goToAccountAction(prevSelectedAccount));
    let showSerials = true;
    if (productIsBBS(selectedProduct)) {
      showSerials = false;
    } else if (serialsToDisplay.length === 0) {
      showSerials = !selectedProduct?.unassigned;
    }
    setShowSerials(showSerials);
    setShowEditSerials(false);
  };

  const onHandleBackToMSPAccounts = (refreshSerials: boolean) => {
    let serials = serialsToDisplay;
    dispatch(cancelLoadSubpartnerCustomersAction());
    dispatch(cancelLoadCustomerParentAndItsChildrenAction());
    setShowLink(false);
    if (refreshSerials) {
      getRefreshedSerialsToDisplay(selectedProduct, refreshSerials).then(updatedSerials => {
        if (updatedSerials) {
          serials = updatedSerials;
          handleSerialsVisibility(prevSelectedAccount, selectedProduct, serials);
        }
      });
    } else {
      handleSerialsVisibility(prevSelectedAccount, selectedProduct, serials);
    }
  };

  const handleOpenManageServiceDialog = (optionIndex: number) => {
    setShowEditSerials(false);
    setShowSerials(false);
    setShowMigrateDialog(true);
  };

  const changeSerial = (orderlineItem: IProduct, serial: ISerial, lineItem: IProduct) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(changeSerialAction(selectedAccount, orderlineItem, serial, lineItem));
      resolve(result);
    });

  const getSerials = (orderlineItem: IProduct, accountId: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getSerialsAction(accountId, orderlineItem));
      resolve(result);
    });

  const onMigrate = (orderLineItem: IProduct, serial: ISerial, lineItem: IProduct) => {
    setShowMigrateDialog(false);
    setMigrationObject({ orderLineItem: orderLineItem, serial: serial, lineItem: lineItem });
    setShowConfirmMigrate(true);
  };

  const navigateToSelectedAccount = () => {
    const targetPath = `/accounts/${selectedAccount?.id}/products-services`;

    if (location.pathname !== targetPath) {
      console.debug(`navigate to targetPath:  ${targetPath} != pathname:${location.pathname}`);
      navigate(targetPath);
    }
  };

  const handleOnCloseMigrate = () => {
    setShowMigrateDialog(false);
    setMigrationObject(undefined);
    navigateToSelectedAccount();
  };

  const handleConfirmMigrate = () => {
    if (migrationObject) {
      setShowConfirmMigrate(false);
      setActionInProgress(true);
      setShowMigrateDialog(true);
      changeSerial(migrationObject.orderLineItem, migrationObject.serial, migrationObject.lineItem).then(result => {
        if (result === true) {
          if (selectedAccount) {
            setMigrationObject(undefined);
            if (showLink && prevSelectedAccount) {
              getSerials(migrationObject.orderLineItem, prevSelectedAccount?.id).then(res => {
                onChangeSerialFinished(dispatch, selectedAccount, setShowMigrateDialog);
                onHandleBackToMSPAccounts(false);
              });
            } else {
              onChangeSerialFinished(dispatch, selectedAccount, setShowMigrateDialog);
            }
          }
        } else {
          setActionInProgress(false);
        }
      });
    }
  };

  const onChangeSerialFinished = (disp: Dispatch<any>, selectedAcc: IAccount | undefined, setShowMigrateDialogCallback: React.Dispatch<React.SetStateAction<boolean>>): void => {
    disp(getProductsAction(selectedAcc));
    disp(getAccountHasAnyChangeServicesOptionsAction(selectedAccount));
    setShowMigrateDialogCallback(false);
    setActionInProgress(false);
    dispatch(
      setSnackBarMessage({
        message: ActionMessages[ActionTypes.ChangeService].infoMessage,
        type: ActionMessageType.Info,
      }),
    );
    if (hasProductFilters(filters)) {
      dispatch(filterAccountsAction(filters));
      navigate("/filters" + window.location.search);
    }
  };

  const handleCloseMigrateConfirmationDialog = () => {
    setShowConfirmMigrate(false);
    setShowMigrateDialog(true);
  };

  const onHandleBackToEditSerial = () => {
    setShowMigrateDialog(false);
    setShowEditSerials(true);
  };

  const onMigrationOptionsCalculated = (options: IProduct[]) => {
    setMigrationOptions(options);
  };
  const handleManageServiceDialog = () => {
    setShowEditSerials(false);
    setShowMigrateDialog(true);
  };

  const handleOnUnassignFromEditSerial = () => {
    setShowUnassignedEditSerial(true);
  };

  const retryActivationProduct = (product: IProduct, serials: ISerial) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(retryActivationProductAction(mspAccountLoggedIn, product, serials, selectedAccount));
      resolve(result);
    });

  const handleOnRetryActivation = () => {
    if (selectedProduct !== undefined) {
      setActionInProgress(true);
      if (selectedAccount) {
        retryActivationProduct(selectedProduct, serialForUser).then(result => {
          if (result) {
            dispatch(
              setSnackBarMessage({
                message: ActionMessages[ActionTypes.RetryActivation].infoMessage,
                type: ActionMessageType.Info,
              }),
            );
            if (selectedAccount) {
              dispatch(getProductsAction(selectedAccount));
              if (nameIsESS(selectedProduct.type)) {
                dispatch(getAccountHasAnyChangeServicesOptionsAction(selectedAccount));
              }
              getSerials(selectedProduct, selectedAccount.id).then(res => {
                if (res) {
                  if (productIsBBS(selectedProduct)) {
                    dispatch(getParentMspProductsAction(selectedAccount));
                  }
                  setActionInProgress(false);
                }
              });
            }
          } else {
            setActionInProgress(false);
            setShowEditSerials(false);
          }
          if (filters?.hasErrors) {
            dispatch(filterAccountsAction(filters));
            navigate("/filters" + window.location.search);
          }
        });
      }
    }
  };

  const getLastUsageSyncTimeToDisplay = (time: string) => {
    const t = new Date(time);
    t.setDate(t.getUTCDate() - 1); // Subtract one day
    return getDateToDisplay(t);
  };

  const renderLastUsageSyncInfo = () => {
    if (productsToDisplay && productsToDisplay.length > 0) {
      return (
        <div>
          <Grid container item xs={12} style={{ alignContent: "left" }}>
            <Typography data-testid="lastUsageSyncTitle" variant="subtitle2" style={{ fontWeight: "bold" }}>
              PRODUCT / SERVICE
            </Typography>
          </Grid>
          <Grid container item xs={12} style={{ alignContent: "left" }}>
            <Typography data-testid="lastUsageSyncTime" variant="subtitle2" style={{ color: "#8a8a8a" }}>
              System updates occur daily. Displayed information accurate as of {getLastUsageSyncTimeToDisplay(lastUsageSyncTimeToDisplay)}
            </Typography>
          </Grid>
        </div>
      );
    }
  };

  useEffect(() => {
    if (showUnassignedEditSerial) {
      handleOpenUnassignedDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUnassignedEditSerial]);

  const handleOpenManageIntegrations = () => {
    dispatch(cancelCurrentAction());

    const targetPath = `/accounts/${selectedAccount?.id}/integrations`;

    navigate(targetPath);
  };

  useEffect(() => {
    setCanManageIntegration(getCanManageIntegrations(selectedAccount, mspAccountLoggedIn));
  }, [mspAccountLoggedIn, selectedAccount]);

  useEffect(() => {
    setCanExportDataUsage(getCanExportDataUsage(mspAccountLoggedIn));
  }, [mspAccountLoggedIn]);

  useEffect(() => {
    setCanExportDataUsageAndBillingList(getCanExportDataUsage(mspAccountLoggedIn) && getCanExportUserBillingList(selectedAccount));
  }, [mspAccountLoggedIn, selectedAccount]);

  const handleExportSelectedAction = (selectedAction: number) => {
    switch (selectedAction) {
      case 0:
        setShowGenereateCsvReport(true);
        break;
      case 1:
        setShowExportUserBillingList(true);
        break;
    }
  };

  const handleOpenGenereateCsvReport = () => {
    setShowGenereateCsvReport(true);
  };

  const handleCancelExportUsage = () => {
    setShowGenereateCsvReport(false);
  };

  const exportCsv = (mspAccountLoggedIn: IAccount, selectedAccount: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(exportCsvReportAction(mspAccountLoggedIn, selectedAccount));
      resolve(result);
    });

  const handelOnExportUsage = () => {
    setActionInProgress(true);
    if (selectedAccount) {
      exportCsv(mspAccountLoggedIn, selectedAccount).then(result => {
        if (result) {
          setShowGenereateCsvReport(false);
          dispatch(
            setSnackBarMessage({
              message: ActionMessages[ActionTypes.ExportCsvReport].infoMessage,
              type: ActionMessageType.Info,
            }),
          );
        }
        setActionInProgress(false);
      });
    }
  };

  const onExportUserBillingListSubmit = () => {
    setShowExportUserBillingList(false);
  };

  const onExportUserBillingListCancel = () => {
    setShowExportUserBillingList(false);
  };

  return (
    <div className={"ProductsTab"}>
      <Grid item container spacing={3} direction="column">
        <Grid item container>
          <Grid container item xs={10}>
            <TabTitle tabType={DetailsTabs.Products} item={selectedAccount} overwriteItem={selectedAccountToFilterProductsForBA} />
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            {!loadingProducts && <ProductsButtonActions />}
            {canManageIntegration && (
              <div>
                <Button data-testid={"ManageIntegrationsButton"} color={manageIntegrationsButtonColor} variant={"contained"} size={"large"} style={{ marginRight: "15px" }} onClick={handleOpenManageIntegrations}>
                  MANAGE INTEGRATIONS
                </Button>
              </div>
            )}
            {canExportDataUsage && !canExportDataUsageAndBillingList && (
              <div>
                <Button data-testid={"ExportUsageData"} color={generateCsvReportButtonColor} variant={"contained"} size={"large"} style={{ marginRight: "15px" }} onClick={handleOpenGenereateCsvReport}>
                  EXPORT USAGE DATA
                </Button>
              </div>
            )}
            {canExportDataUsageAndBillingList && (
              <div>
                <ExportDataSplitButton data-testid="exportSplitButton" buttonColor={generateCsvReportButtonColor} setSelectedAction={handleExportSelectedAction} />
              </div>
            )}
          </Grid>
        </Grid>
        {isBaLoggedIn && !isSelectedAccountBa && (
          <Grid container item xs={12} style={{ justifyContent: "center" }} spacing={3}>
            <ProductFilters />
          </Grid>
        )}
        <Grid item xs={12}>
          {!loadingProducts && renderLastUsageSyncInfo()}
        </Grid>
        <Grid item xs={12}>
          {showHideLinearProgress ? (
            <div data-testid={"loadingProducts"}>
              <LinearProgress />
            </div>
          ) : (
            accordions
          )}
        </Grid>
      </Grid>

      {showSerials && selectedProduct && <ShowSerialsDialog showDialog={showSerials} onCancel={handleOnCancel} onGoToAccount={handleGoToAccount} isSecureEdgeEdgeService={isSecureEdgeEdgeService} dialogTitle={selectedProduct?.subname} onShowUnassignedDialog={handleOpenUnassignedDialog} showUnassignButton={showUnassignButton(selectedAccount, mspAccountLoggedIn)} disableUnassignButton={selectedProduct.unassigned} />}
      {showEditSerials && selectedProduct && serialForUser && <ShowEditSerialsDialog showDialog={showEditSerials} product={selectedProduct} onDeactivate={handleOnDeactivateSerial} onCancel={handleOnCancelEditSerial} onUnassign={handleOnUnassignFromEditSerial} onRetryActivation={handleOnRetryActivation} serialForUser={serialForUser} displayLink={showLink} onHandleBackToMSPAccounts={onHandleBackToMSPAccounts} handleOpenManageServiceDialog={handleOpenManageServiceDialog} onMigrationOptionsCalculated={onMigrationOptionsCalculated} onManageService={handleManageServiceDialog} showUnassignButton={showUnassignButton(selectedAccount, mspAccountLoggedIn)} isActionInProgress={actionInProgress} isLoadingParent={loadingAccountToGoTo || actionInProgress} parentIsNotMsp={parentIsNotMsp} isSecureEdgeProducts={isSecureEdgeProducts} />}
      {showUnassignedDialog && <ShowUnassignedDialog showUnassignedDialog={showUnassignedDialog} onCancel={handleOnCancelUnassigned} onUnassign={handleOnUnassign} isActionInProgress={actionInProgress} />}
      {showDeleteSerialDialog && <ShowDeleteSerialDialog showDeleteSerialDialog={showDeleteSerialDialog} onBack={handleOnBackDeactivate} isActionInProgress={actionInProgress} selectedProduct={selectedProduct} onSubmitDeleteSerial={handleOnDeactivate} onCancel={handleOnCloseDeactivate} />}
      {showConfirmMigrate && <ShowMigrateConfirmationDialog showDialog={showConfirmMigrate} onCancel={handleCloseMigrateConfirmationDialog} isActionInProgress={actionInProgress} onSubmit={handleConfirmMigrate} migrationOption={migrationObject} />}
      {showMigrateDialog && selectedProduct && <MigrateDialog showDialog={showMigrateDialog} onCancel={handleOnCloseMigrate} onSubmit={onMigrate} product={selectedProduct} products={migrationOptions} serial={serialForUser} onHandleBackToEditSerial={onHandleBackToEditSerial} isActionInProgress={actionInProgress} migrateToProd={migrationObject?.lineItem} />}
      {showGenereateCsvReport && selectedAccount && <ExportUsageDialog showExportUsageDialogDialog={showGenereateCsvReport} onCancel={handleCancelExportUsage} isActionInProgress={actionInProgress} onExportUsageData={handelOnExportUsage} selectedAccount={selectedAccount} emailAddress={loggedUser.email} />}
      {showExportUserBillingList && <ExportUserBillingListDialog showDialog={showExportUserBillingList} isActionInProgress={actionInProgress} dialogStep={0} title="Export User Billing List" onSubmit={onExportUserBillingListSubmit} onCancel={onExportUserBillingListCancel} selectedAccount={selectedAccount} />}
    </div>
  );
};
export default ProductsTab;
