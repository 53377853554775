import React from "react";
import { Typography } from "@cuda-networks/bds-core";

interface ISummaryItemProps {
  name: string;
  serialNumber: string;
  uniqueId: string;
}

const SummaryItem: React.FC<ISummaryItemProps> = ({ name, serialNumber, uniqueId }) => {
  return (
    <div
      style={{
        backgroundColor: "#d9d9d9",
        borderRadius: "8px",
        padding: "20px",
      }}
      className="ChangeFrom"
    >
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", flexWrap: "wrap" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div>
            <Typography variant="subtitle1" style={{ fontSize: "14px", fontWeight: "600" }} data-testid="changeActiveServiceFromSummaryItemProductName">
              {name}
            </Typography>
          </div>
          <div style={{ textAlign: "right" }}>
            <Typography variant="body2" style={{ textAlign: "right" }} data-testid="changeActiveServiceFromSummaryItemSerialNumber">
              Serial#: {serialNumber.trim()}
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant="body2" data-testid="changeActiveServiceFromSummaryItemUniqueId">
            {uniqueId}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SummaryItem;
