import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import IProduct from "../../../../models/Products/IProduct";
import { Typography } from "@cuda-networks/bds-core";
import FromSelectionItem from "../FromSelectionItem";
import { Link } from "@cuda-networks/bds-core";
import IChangeServiceSelection from "../../../../models/Products/IChangeServiceSelection";
import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";

interface IFromStepProps {
  activatedProducts: IProduct[];
  selection: IChangeServiceSelection;
  onFromSelectionChanged: (product: IProduct, oldProduct?: IProduct) => void;
  onDelete(product: IProduct): void;
}

const FromStep: React.FC<IFromStepProps> = ({ activatedProducts, selection, onFromSelectionChanged, onDelete }) => {
  const onAddNewClick = (event: React.SyntheticEvent) => {
    event?.preventDefault();
    onFromSelectionChanged({ id: "" } as IProduct);
  };

  const tooltipLink = () => {
    if (activatedProducts.length === 0) {
      return "There are no products available for change";
    }

    if (selection.from.some(item => item.id === "")) {
      return "Make sure the above field is not empty";
    }

    return "Click to add another product or service";
  };

  return (
    <Grid container style={{ width: "600px" }}>
      <Typography data-testid="changeServicesDialogSubtitle" variant="subtitle2" style={{ fontWeight: "600", paddingLeft: "16px", paddingRight: "16px" }}>
        FROM
      </Typography>
      <Typography data-testid="changeServicesDialogLabel1" variant="subtitle2" style={{ paddingLeft: "16px", paddingRight: "16px" }}>
        In the fields below, select the Active Service from which you want to change. Repeat for each service you want to change. When complete, click the <b>Next</b> button.
      </Typography>
      <div style={{ height: "16px", width: "1px" }} />
      <Grid item xs={12}>
        {selection.from?.map((item, idx) => {
          if (item.id === "") {
            return (
              <div style={{ marginBottom: "10px", paddingLeft: "16px", paddingRight: "16px" }} key={"changeServicesFrom" + item.id} data-testid={"changeServicesFrom" + item.id}>
                <FromSelectionItem products={activatedProducts} onSelectionChange={onFromSelectionChanged} onDelete={onDelete} selectedItem={undefined} showDeleteButton={selection.from.length > 1} />
              </div>
            );
          }
          return (
            <div style={{ marginBottom: "10px", paddingLeft: "16px", paddingRight: "16px" }} key={"changeServicesFrom" + item.id} data-testid={"changeServiceFroms" + item.id}>
              <FromSelectionItem products={[...activatedProducts, item].sort((a, b) => a.name.localeCompare(b.name))} onSelectionChange={onFromSelectionChanged} onDelete={onDelete} selectedItem={item} showDeleteButton={selection.from.length > 1} />
            </div>
          );
        })}
      </Grid>
      <Tooltip title={tooltipLink()}>
        <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingTop: "10px" }}>
          <Link
            data-testid="addAnotherServiceForChangeLink"
            variant="subtitle2"
            target="_blank"
            href=""
            style={{
              pointerEvents: activatedProducts.length > 0 && !selection.from.some(item => item.id === "") ? "auto" : "none",
              opacity: activatedProducts.length > 0 && !selection.from.some(item => item.id === "") ? 1 : 0.5,
            }}
            onClick={onAddNewClick}
          >
            + Add Another Product / Service
          </Link>
        </div>
      </Tooltip>
    </Grid>
  );
};

export default FromStep;
